(function () {
	'use strict';
	angular
		.module('app')
		.controller('SeedcodeCtrl', [
			'$scope',
			'firebaseIO',
			'seedcodeDev',
			SeedcodeCtrl,
		]);

	function SeedcodeCtrl($scope, firebaseIO, seedcodeDev) {
		$scope.seedcodeAdminProps = {
			firebaseIO: firebaseIO,
			accessCredentials: seedcodeDev.getAccessCredentials(),
		};
	}
})();
